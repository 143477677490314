.LoadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  background: rgba(255, 255, 255, 0.5);

  &.hidden {
    display: none;
  }

  span {
    font-size: 1.5em;
  }
}
