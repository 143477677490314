@keyframes jumping-scale {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.ellipsis-container {
    height: 1em;
}

.ellipsis-dot {
    background: currentColor;
    display: inline-block;
    width: 0.25em;
    height: 0.25em;
    border-radius: 50%;
    margin-left: 0.25em;
    margin-bottom: 0.20em;
    animation: jumping-scale 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.ellipsis-dot:nth-child(1) {
    animation-delay: -0.45s;
}
.ellipsis-dot:nth-child(2) {
    animation-delay: -0.3s;
}
.ellipsis-dot:nth-child(3) {
    animation-delay: -0.15s;
}
