.StatusBar {

  &-wrapper {
    //padding: 0 50px;
    height: 50px;
    display: flex;
  }

  &-status {
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding: 0 50px;

    &-block {
      height: 100%;
      margin: 0;
      display: flex;

      p {
        margin: auto;
      }
    }
  }

  &-theme {
    width: 100%;
    max-width: 50px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
  }

  .btn-theme {
    margin: auto;
    width: 50px;
    height: 100%;
    color: var(--color-otherTheme)
  }
}
